import LoginBgImg from '@assets/icons/Footer Square.svg';
import LoginTabBgImg from '@assets/icons/Frame 1000004486.svg';
import LoginMobBgImg from '@assets/icons/Mob-Login-bg.svg';
import LoginSeedFlexLogo from '@assets/icons/Logo Unit Grp - Clr1.svg';
import LoginSeedflexWithPay from '@assets/icons/seedflex-logo-login.svg';
import LoginSenangPay from '@assets/icons/senang-pay.svg';
import LoginCurlecPay from '@assets/icons/Curlec-logo.svg';
import LoginIpay88Pay from '@assets/icons/iPay-logo.svg';
import LoginPineLabs from '@assets/icons/pine-labs-logo.svg';
import LoginMobility from '@assets/icons/mobility-logo.svg';
import LoginEasyEat from '@assets/icons/easyeatLogo.svg';
import LoginLOLA from '@assets/icons/lolaLogo.svg';
import LoginCHIP from '@assets/icons/Chip-logo.svg';
import LoginDOKU from '@assets/icons/DOKU-logo.svg';
import SecurityIcon from '@assets/icons/SecurityIcon.svg';
import Certified from '@assets/icons/Certified.svg';
import {loginActions} from '@pages/Login/auth/store/loginReducer';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {LANGUAGES, LOGIN_PATNERS, WHATS_APP_NUMBER} from '@common/constants';
import {useTranslation} from 'react-i18next';
import {
  getCountryCodeFromUrl,
  getDeploymentStatus,
  getImageApi,
} from '@shared/api/api';
import {dispatch} from '@store';
import LoginContainer, {CertifiedDetails} from './index.style';

interface CountryInfo {
  countryCode: string;
  countryMobileCode: string;
  countryName: string;
}

interface PartnerLogo {
  keyword: string;
  logo: string;
}

interface PartnerLogoBackup {
  [key: string]: string;
}

interface PartnerLogoConfig {
  logo: string;
  alt: string;
  style: React.CSSProperties;
}

const PARTNER_LOGOS: Record<string, PartnerLogoConfig> = {
  Curlec: {logo: LoginCurlecPay, alt: 'curlec-logo', style: {}},
  senangPay: {logo: LoginSenangPay, alt: 'senang-pay-logo', style: {}},
  'MobilityOne Sdn Bhd': {
    logo: LoginMobility,
    alt: 'mobility-one-logo',
    style: {width: '180px', height: '60px', marginTop: '10px'},
  },
  'Pine Labs': {logo: LoginPineLabs, alt: 'pine-labs-logo', style: {}},
  LOLA: {
    logo: LoginLOLA,
    alt: 'lola-logo',
    style: {width: '180px', height: '60px'},
  },
  EasyEat: {
    logo: LoginEasyEat,
    alt: 'easy-eat-logo',
    style: {width: '180px', height: '60px'},
  },
  iPay: {logo: LoginIpay88Pay, alt: 'ipay88-logo', style: {}},
  FinMatch: {logo: LoginIpay88Pay, alt: 'ipay88-logo', style: {}},
  Chip: {
    logo: LoginCHIP,
    alt: 'chip-logo',
    style: {height: '60px', width: '180px'},
  },
  DOKU: {logo: LoginDOKU, alt: 'doku-logo', style: {}},
};

const getPartnerLogo = (partnerName: string | null) => {
  if (!partnerName) return null;
  const lowerCaseName = partnerName.toLowerCase();
  return Object.entries(PARTNER_LOGOS).find(([key]) =>
    lowerCaseName.includes(key.toLowerCase())
  )?.[1];
};

// Main Component
function Maintenance() {
  const [partnerName, setPartnerName] = useState<string>('');
  const [partnerLogo, setPartnerLogo] = useState<string | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const partner = searchParams.get('partner');
  const shopifyStoreUrl = searchParams.get('shopifyStoreUrl');
  const {t} = useTranslation();
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');

  useEffect(() => {
    if (partner && LOGIN_PATNERS.includes(partner)) {
      setPartnerName(partner);
      const logo = getPartnerLogo(partner);
      setPartnerLogo(logo?.logo || null);
    }
    if (shopifyStoreUrl) {
      dispatch(loginActions.setShopifyStoreUrl(shopifyStoreUrl));
    }
  }, [partner, shopifyStoreUrl]);

  useEffect(() => {
    const checkMaintenance = async () => {
      const status = await getDeploymentStatus();
      setFrom(status.data?.from);
      setTo(status.data?.to);
    };

    checkMaintenance();
  }, []);

  return (
    <LoginContainer>
      <div className='content'>
        <div className='background-img'>
          <img src={LoginBgImg} alt='login-bg-img-web' />
        </div>
        <div className='background-mob-img'>
          <img src={LoginMobBgImg} alt='login-bg-img-mob' />
        </div>
        <div className='background-tab-img'>
          <img src={LoginTabBgImg} alt='login-bg-img-tab' />
        </div>
        <div className='login-from'>
          <div className='login-seedflex-logo'>
            {partnerLogo ? (
              <div className='two-logo'>
                <img src={LoginSeedflexWithPay} alt='seedflex-logo' />
                <img
                  src={partnerLogo}
                  alt={PARTNER_LOGOS[partnerName]?.alt || 'partner-logo'}
                  style={PARTNER_LOGOS[partnerName]?.style}
                />
              </div>
            ) : (
              <img src={LoginSeedFlexLogo} alt='seedflex-logo' />
            )}
          </div>
          <div className='maintenance-texts'>
            <h3 className='maintenance-title'>Scheduled Maintenance:</h3>
            <span className='maintenance-note'>
              Due to maintenance activity, our platform is not available between{' '}
              {from} to {to} (Malaysia Time).
              <br />
              <br />
              For any urgent issues, please reach out to us on WhatsApp at{' '}
              {WHATS_APP_NUMBER}
            </span>
          </div>
          <CertifiedDetails>
            <div className='certified-icon'>
              <img src={Certified} alt='certified-icon' />
              <div className='certified-details-text'>
                <div className='certified-details-title'>
                  {t('login.MP_LOGIN_HELPTEXT_SHARIAH')}
                </div>
                <div className='certified-details-sub-title'>
                  {t('login.MP_LOGIN_HELPTEXT_TAGLINE')} <sup>TM</sup>
                </div>
              </div>
            </div>
            <div className='certified-icon'>
              <img src={SecurityIcon} alt='certified-icon' />
              <div className='certified-details-text'>
                <div className='certified-details-title'>
                  {t('login.MP_LOGIN_HELPTEXT_SECURITY')}
                </div>
                <div className='certified-details-sub-title'>
                  {t('login.MP_LOGIN_HELPTEXT_SSL')}
                </div>
              </div>
            </div>
          </CertifiedDetails>
        </div>
      </div>
    </LoginContainer>
  );
}

export default Maintenance;
