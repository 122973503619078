import Loader from '@shared/components/Loader';
import {RootState} from '@store';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {I18nextProvider} from 'react-i18next';
import {getDeploymentStatus} from '@shared/api/api';
import Maintenance from '@pages/Maintenance';
import i18n from './i18n';
import AppContainer from './App.style';
import AppRoutes from './routes';

export default function App() {
  const {pathname} = useLocation();
  const [isMaintenance, setIsMaintenance] = useState<boolean | null>(false);

  useEffect(() => {
    document.body.style.cssText = 'zoom: 100%';

    // Clean up the style on component unmount
    return () => {
      document.body.style.cssText = '';
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const checkMaintenance = async () => {
      const status = await getDeploymentStatus();
      setIsMaintenance(status.data?.isInMaintenance);
    };

    checkMaintenance();
  }, []);

  const {
    loader: {isAppLoading},
  } = useSelector((state: RootState) => state);

  return (
    <AppContainer>
      <I18nextProvider i18n={i18n}>
        {isMaintenance ? (
          <Maintenance />
        ) : (
          <>
            <AppRoutes />
            {isAppLoading && <Loader />}
          </>
        )}
      </I18nextProvider>
    </AppContainer>
  );
}
