import {
  AUTH_API_ENDPOINTS,
  domainToCountryCodeMap,
  VERSIONS,
} from '@common/constants';
import {get} from '@core/api/axios';
import {AxiosResponse} from 'axios';

interface INameImage {
  name: string;
}

export const getImageApi = async (data: INameImage) => {
  const response = await get(
    `${VERSIONS.V1}${AUTH_API_ENDPOINTS.IMAGE}?imageName=${data.name}`
  );
  return response as AxiosResponse;
};

export const getCountryCodeFromUrl = (): string => {
  if (typeof window === 'undefined') {
    return 'SG'; // Default country code
  }

  const {hostname} = window.location;
  const matchingDomain = Object.keys(domainToCountryCodeMap).find((domain) =>
    hostname.endsWith(domain)
  );

  // Return the country code if a match is found, otherwise default to 'SG'
  return matchingDomain ? domainToCountryCodeMap[matchingDomain] : 'SG';
};

export const getDeploymentStatus = async () => {
  const response = await get(`${VERSIONS.V1}${AUTH_API_ENDPOINTS.MAINTENANCE}`);
  return response as AxiosResponse;
};
