import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import {Box, Button, Checkbox, Input, Typography} from '@mui/material';
import styled from 'styled-components';

const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.baseColors.primaryColor};
  .content {
    width: 1120px;
    height: 560px;
    box-shadow: 24px 24px 48px 0px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  .background-img {
    width: 50%;
  }
  .background-mob-img {
    display: none;
  }
  .background-tab-img {
    display: none;
  }
  .login-from {
    width: 50%;
    height: 560px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0px 20px 20px 0px;
    .MuiBox-root {
      width: 100%;
      text-align: center;
    }
  }

  .login-seedflex-logo {
    margin-top: 94px;
    .two-logo {
      display: flex;
      width: 290px;
      justify-content: space-between;
    }
  }

  .error-msg {
    color: #ba0f0f;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.005em;
    margin-left: 20px;
    margin-top: 10px;
  }
  .maintenance-texts {
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: 60px;

    .maintenance-title {
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 900;
      margin: 40px 0;
      line-height: 22px;
      letter-spacing: 0em;
      color: red;
      text-align: center;
    }

    .maintenance-note {
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 40px;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }

  @media (max-width: 1120px) {
    background-color: #fafafa;
    justify-content: center;
    align-items: flex-start;
    .content {
      flex-direction: column;
      height: auto;
      width: 100%;
      box-shadow: none;
    }
    .background-img {
      display: none;
    }
    .background-tab-img {
      display: flex;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .background-mob-img {
      display: none;
    }
    .login-from {
      width: 100%;
      justify-content: start;
    }
    .login-note {
      margin-top: 48px;
    }
    .login-input {
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .login-link-btn {
      margin-top: 50px;
    }
  }
  @media (max-width: 550px) {
    .background-tab-img {
      display: none;
    }
    .background-mob-img {
      display: flex;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .background-img {
      display: none;
    }
  }
`;

export const FormContainer = styled(Box)`
  width: 50%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-radius: 0px 20px 20px 0px;
  .back-btn-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    h3 {
      width: fit-content;
      margin-left: 20px;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 1120px) {
    width: 80%;
  }
  @media (max-width: 820px) {
    width: 100%;
    .background-tab-img {
      display: none;
    }
  }
`;

export const BoxBtn = styled.div`
  width: 95%;
  text-align: center;
`;

export const WatsAppText = styled.p`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.005em;
  letter-spacing: 0.005em;
  text-align: left;
  max-width: 357px;
  color: #121212;
  padding-top: 5px;
  margin-top: 20px;
`;

export const CredentialBox = styled.div`
  width: 100%;
  text-align: center;
`;
export const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CardNumber = styled.div`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.baseColors.text};
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    border-radius: 50%;
    padding: 0px;
  }
`;

export const CertifiedDetails = styled.div`
  width: 100%;
  display: flex;
  column-gap: 30px;
  padding-top: 15px;
  padding-left: 50px;
  justify-content: center;
  .certified-icon {
    display: flex;
    margin-bottom: 20px;
  }
  .certified-details-text {
    color: #505050;
    font-size: 10px;
    margin-left: 6px;
    font-family: Nunito Sans;
    .certified-details-title {
      font-family: inherit;
      font-weight: 700;
    }
    .certified-details-sub-title {
      width: 136px;
      font-weight: 400;
      font-family: inherit;
      sup {
        font-family: inherit;
        font-size: 7px;
      }
    }
  }
  @media (max-width: 480px) {
    padding-left: 0px;
  }
`;

export const LangDropdown = styled(Box)`
  width: 50%;
  margin-top: 30px;
  .MuiInputBase-root {
    width: 50%;
    height: 50px;
    padding-left: 16px;
    .MuiSelect-select:focus {
      background: #ffffff;
      width: 80%;
    }
  }
`;

export const AgreeTNC = styled(Typography)`
  && {
    color: #121212;
    font-size: 10px;
    font-style: italic;
    font-family: Nunito Sans;
    margin-top: 10px;
    a {
      color: ${(props) => props.theme.baseColors.primaryColor};
      text-decoration: none;
    }
  }
`;

export default LoginContainer;
