import {ReactComponent as Logo} from '@assets/icons/seedflexLogo.svg';
import {PATH} from '@common/constants';
import {Box, Button, Typography} from '@mui/material';
import {loginActions} from '@pages/Login/auth/store/loginReducer';
import {dispatch} from '@store';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

interface INotFound {
  showEmpty: boolean;
}

interface IContainer {
  blank: boolean;
}
const Title = styled(Typography)`
  && {
    color: ${({theme}) => theme.baseColors.primaryColor};
    text-align: center;
    font-family: Montserrat;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`;

const Message = styled(Typography)`
  && {
    color: ${({theme}) => theme.baseColors.text};
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.1px;
  }
`;

const HomeBtn = styled(Button)`
  && {
    border-radius: 20px;
    background: ${({theme}) => theme.baseColors.primaryColor};
    display: flex;
    width: 327px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.baseColors.white};
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.07px;
    &:hover {
      background: ${({theme}) => theme.baseColors.primaryColor};
    }
  }
`;
const Container = styled(Box)<IContainer>`
  && {
    display: ${({blank}) => (blank ? 'none' : 'flex')};
    flex-direction: column;
    align-items: center;
    gap: 150px;
    margin-top: 100px;
    margin-left: -100px;
    @media (max-width: 541px) {
      margin-left: 0;
      margin-right: 50px;
    }
    @media (max-width: 480px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const TitleMessage = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 75px;
  }
`;
function NotFound({showEmpty}: INotFound) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleNavigate = () => {
    dispatch(loginActions.setNotFoundData(false));
    navigate(PATH.DASHBOARD);
  };

  return (
    <div>
      <Container blank={showEmpty}>
        <Logo />

        <TitleMessage>
          <Title>404</Title>
          <Message>{t('404.MP_404_NOTE')}</Message>
        </TitleMessage>

        <HomeBtn onClick={handleNavigate}>{t('404.MP_404_BACK_BTN')}</HomeBtn>
      </Container>
    </div>
  );
}

export default NotFound;
